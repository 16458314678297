<template>
  <div>
    <div class="uk-section uk-section-xsmall uk-padding-remove-top">
      <div data-uk-sticky="offset:52;media: @m" class="uk-background-default breadcrumb" style="padding: 15px 35px; border-bottom: 1px solid rgb(229, 229, 229)">
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div class="uk-flex uk-flex-middle">
            <h1 class="uk-h5 uk-margin-remove uk-flex uk-flex-middle" style="color: rgb(51, 51, 51)">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" class="uk-margin-small-right">
                <path
                  d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
                />
              </svg> {{ headerLabel }}
            </h1>
          </div>
          <div>
            <a href="#" @click.prevent="$router.go(-1)" class="uk-button uk-button-text">Schließen</a>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-container uk-container-expand uk-margin-top">
      <div class="uk-section uk-section-xsmall uk-margin-large-bottom">
        <div class="uk-grid" data-uk-grid>
          <div class="uk-width-1-4@l">
            <h4 class="uk-margin-small">Stammdaten</h4>
            <div class="uk-text-muted uk-text-small">
              Die Stammdaten des Kunden
            </div>
          </div>
          <div class="uk-width-expand">
            <ValidationObserver ref="observer">
              <div class="uk-card uk-card-default">
                <div class="uk-card-body">
                  <div class="uk-width-xlarge@l">
                    <form class="uk-form-stacked" @submit.prevent="handleSubmit">
                      <div class="uk-grid uk-grid-small" data-uk-grid>
                        <div class="uk-width-1-1">
                          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                            <label><input class="uk-radio" type="radio" v-model="form.type" value="customer"> Privatkunde</label>
                            <label><input class="uk-radio" type="radio" v-model="form.type" value="company"> Firmenkunde</label>
                            <label><input class="uk-radio" type="radio" v-model="form.type" value="supplier"> Lieferant</label>
                        </div>
                        </div>
                        <div class="uk-width-1-1" v-if="form.type !== 'customer'">
                          <label class="uk-form-label">Firma</label>
                          <div class="uk-form-controls">
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.company"
                            />
                          </div>
                        </div>
                        <div class="uk-width-1-1" v-if="form.type === 'customer'">
                          <label class="uk-form-label">Anrede</label>
                          <div class="uk-form-controls">
                            <select
                              class="uk-select uk-border-rounded"
                              v-model="form.salutation"
                            >
                              <option value="Herr">Herr</option>
                              <option value="Frau">Frau</option>
                            </select>
                          </div>
                        </div>
                        <div class="uk-width-1-1" v-if="form.type !== 'customer'">
                          <span class="uk-text-bold">Ansprechpartner</span>
                        </div>
                        <div class="uk-width-1-2@l">
                          <label class="uk-form-label">Vorname<span v-if="form.type === 'customer'">*</span></label>
                          <div class="uk-form-controls">
                            <ValidationProvider
                              :rules="{required: form.type === 'customer'}"
                              name="firstname"
                              v-slot="{ errors }"
                            >
                              <input
                                class="uk-input uk-border-rounded"
                                type="text"
                                v-model="form.firstname"
                                :class="{ 'uk-form-danger': errors[0] }"
                              />
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="uk-width-1-2@l">
                          <label class="uk-form-label">Nachname<span v-if="form.type === 'customer'">*</span></label>
                          <div class="uk-form-controls">
                            <ValidationProvider
                              :rules="{required: form.type === 'customer'}"
                              name="lastname"
                              v-slot="{ errors }"
                            >
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.lastname"
                              :class="{ 'uk-form-danger': errors[0] }"
                            />
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="uk-width-1-1 uk-margin" v-if="form.type !== 'customer'">
                          <hr>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">ASP</label>
                          <div class="uk-form-controls">
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.asp"
                            />
                          </div>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">Straße*</label>
                          <div class="uk-form-controls">
                            <ValidationProvider
                              :rules="'required'"
                              name="street"
                              v-slot="{ errors }"
                            >
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.street"
                              :class="{ 'uk-form-danger': errors[0] }"
                            />
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="uk-width-1-2@l">
                          <label class="uk-form-label">PLZ*</label>
                          <div class="uk-form-controls">
                            <ValidationProvider
                              :rules="'required'"
                              name="zipcode"
                              v-slot="{ errors }"
                            >
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.zipcode"
                              :class="{ 'uk-form-danger': errors[0] }"
                            />
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="uk-width-1-2@l">
                          <label class="uk-form-label">Ort*</label>
                          <div class="uk-form-controls">
                            <ValidationProvider
                              :rules="'required'"
                              name="city"
                              v-slot="{ errors }"
                            >
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.city"
                              :class="{ 'uk-form-danger': errors[0] }"
                            />
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">E-Mail</label>
                          <div class="uk-form-controls">
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.email"
                            />
                          </div>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">Telefon</label>
                          <div class="uk-form-controls">
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.phone"
                            />
                          </div>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">Fax</label>
                          <div class="uk-form-controls">
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.fax"
                            />
                          </div>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">Mobil</label>
                          <div class="uk-form-controls">
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.mobile"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="uk-card-footer">
                  <div class="uk-text-right">
                    <button
                      class="uk-button uk-button-primary uk-border-rounded uk-margin-right"
                      type="submit"
                      :disabled="sending"
                      @click.prevent="handleSubmit(true)"
                    >
                      Speichern und Schließen
                    </button>
                    <button
                      class="uk-button uk-button-primary uk-border-rounded"
                      type="submit"
                      :disabled="sending"
                      @click.prevent="handleSubmit(false)"
                    >
                      Speichern
                    </button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerService from '@/services/customer.service'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Pflichtfeld',
})

export default {
  data() {
    return {
      sending: false,
      form: {
        id: null,
        company: '',
        salutation: 'Herr',
        firstname: '',
        lastname: '',
        asp: '',
        street: '',
        zipcode: '',
        city: '',
        email: '',
        phone: '',
        fax: '',
        mobile: '',
        type: ''
      },
    }
  },
  mounted() {
    if (typeof this.$route.params.id !== 'undefined') {
      this.getCustomer(this.$route.params.id)
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    headerLabel() {
      let label = 'Kunde erstellen'
      if(this.form.id) {
        if(this.form.company) {
          label = this.form.company
        } else {
          label = `${this.form.firstname} ${this.form.lastname}`
        }
      }
      return label
    }
  },
  methods: {
    getCustomer(id) {
      CustomerService.get(id).then((response) => {
        this.form = response.data
      })
    },
    async handleSubmit(close) {
      const observer = this.$refs.observer
      const success = await observer.validate()

      if (!success) {
        for (const key of Object.keys(observer.fields)) {
          if (observer.fields[key].invalid) {
            window.scroll({ top: (observer.refs[key].$el.offsetTop - 50), left: 0, behavior: 'smooth' });
            return
          }
        }
      }

      this.store(close)
    },
    async store(close) {
      this.sending = true
      let response = null
      if (this.form.id) {
        try {
          response = await CustomerService.update(this.form.id, this.form)
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          response = await CustomerService.store(this.form)
        } catch (error) {
          console.log(error)
        }
      }

      if (response) {
        this.getCustomer(response.data.id)
      }

      this.sending = false

      iziToast.success({
        title: 'OK',
        message: 'Kunde gespeichert!',
        timeout: 3000,
        position: 'bottomRight',
        transitionIn: 'fadeInLeft',
      })

      if(close) {
        this.$router.push({
          name: 'customer'
        })
      }
    },
  },
}
</script>
